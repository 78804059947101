const INTEREST_TRACKER_VIEW_THRESHOLD = 3;

export const Global = class {

	/**
	 * Setup Main Navigation
	 */
	mainNavigation() {
		// console.log("this is the main navigation");
	}

	breadcrumbs(){
		const maxLevelsDisplayed = 2;
		let breadcrumb = document.querySelector('.breadcrumb_last');

		for(let i=0; i <= maxLevelsDisplayed;i++){
			if(breadcrumb){
			breadcrumb.classList.add('visible');
			} else{
				break;
			}
			breadcrumb = breadcrumb.parentNode;
		}
	}

	footerSignup() {
		const footerSignupForm = document.getElementById('footerSignup');

		footerSignupForm.querySelectorAll('input').forEach(el => {
			el.addEventListener('input', e => {
				const validity = e.target.validity;
				if (validity.valueMissing) {
					e.target.setCustomValidity('This field is required');
				} else if (e.target.validity.typeMismatch) {
					if (e.target.type === 'email') {
						e.target.setCustomValidity('Please enter a valid email address');
					}
				} else if (e.target.validity.patternMismatch) {
					if (e.target.classList.contains('input-zip')) {
						e.target.setCustomValidity('Please enter a valid ZIP code');
					}
				} else {
					e.target.setCustomValidity('');
				}
			});
		});

		footerSignupForm.addEventListener('submit', async (e) => {
			const apiUrl = e.target.action;
			const formBody = {
				email: document.getElementById('email').value,
				firstName: document.getElementById('firstName').value,
				lastName: document.getElementById('lastName').value,
				zip: document.getElementById('zip').value,
			};
			e.preventDefault();

			const customData = await bsmhfLuminate.checkInterests();

			// Attempt to get the user's tracked interests from the custom data object
			try {
				if (customData?.interests?.InterestTracker) {
					const interestList = [];

					for (let interest of customData.interests.InterestTracker) {
						if (interest.viewCount > INTEREST_TRACKER_VIEW_THRESHOLD) {
							interestList.push(interest.interestId);
						}
					}

					if (interestList.length) {
						formBody.interests = interestList.join(',');
					}
				}
			} catch(e) {
				console.log('Unable to get tracked interests');
			}

			// Attempt to get the user's primary market
			try {
				if (customData?.constituent?.user?.primaryMarket) {
					formBody.market = customData.constituent.user.primaryMarket;
				}
				else if(customData?.detectedPrimaryMarket) {
					formBody.market = customData.detectedPrimaryMarket;
				}
			} catch(e) {
				console.log('Unable to get primary user market');
			}

			let response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formBody)
			});
			let responseData = await response.json();
			console.log(responseData);

			document.getElementById('footerSignup').classList.add('hidden');

			if (response.status === 200) {
				document.getElementById('footerSignupSuccess').classList.remove('hidden');
			} else {
				document.getElementById('footerSignupError').classList.remove('hidden');
			}
		});
	}

	/**
	 * Container Initialization
	 */
	init() {
		this.mainNavigation();
		// Code that runs everywhere goes here!

		this.breadcrumbs();

		var searchField = document.getElementsByClassName('fl-search-input')[0];
		searchField.addEventListener('click', function(e) {
			e.stopPropagation();
		});

		var navToggle = document.getElementById('js-nav-toggle');
		navToggle.addEventListener('click', function() {
			var mobileNav = document.getElementById('js-mobile-nav');
			var topBar = document.getElementById('js-mobile-top-bar');
			mobileNav.classList.toggle('top-nav__open');
			topBar.classList.toggle('top-nav__mobile--open');
		});

		this.footerSignup();	
	}
};

const global = new Global();
global.init();
